import { StaticQuery, graphql } from "gatsby"
import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import parse from "html-react-parser"

import "../styles/pages/seo-post.scss"

import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import Frame from "../components/common/frame"
import SidebarSmall from "../components/common/sidebar-small"
import { InPageDropDown } from "../components/common/dropdown-component/makeDropdown"
import Buttons from "../components/common/button"

function SeoPost({ pageContext }) {
  const { seo } = pageContext

  useEffect(() => {
    var allH2 = document.querySelectorAll("#seoContent > .wrap")
    allH2.forEach((tag, index) => {
      tag.id = index
    })
  }, [])

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentSEOPost {
            SuperOps {
              pages(where: { title: "SEO Post" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <div className="blg">
            {data.SuperOps.pages.map(page => {
              const { title, navigationBlock } = page

              return (
                <>
                  {seo.scriptJson.length > 0 && (
                    <script type="application/ld+json">
                      {JSON.stringify(seo.scriptJson)}
                    </script>
                  )}
                  <Frame
                    seo={seo.seo}
                  >
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page={title}
                        color={seo.heroBgColor}
                      />
                    </header>

                    <section className="seo-main">
                      <div
                        className="seo-hero"
                        style={{ background: seo.heroBgColor }}
                      >
                        {/* Conditional rendering for subtitle exist */}
                        {seo.subTitle ? (
                          <Container>
                            {seo.tag && (
                              <div className="seo-tag">{seo.tag}</div>
                            )}
                            <Row>
                              <Col lg={6}>
                                <Slide delay="200">
                                  <div className="seo-hero-heading">
                                    <h1> {seo.title} </h1>
                                  </div>
                                </Slide>
                              </Col>

                              <Col lg={5}>
                                <Slide delay="200">
                                  {/* <div className="seo-tag">{seo.tag}</div> */}
                                  <div className="seo-hero-subtitle p16">
                                    <p className="subtext">{seo.subTitle} </p>
                                    {seo.heroButtonText && (
                                      <Buttons
                                        theme="primary sm"
                                        arrow
                                        text={seo.heroButtonText}
                                        link={seo.heroButtonLink}
                                      />
                                    )}
                                  </div>
                                </Slide>
                              </Col>
                            </Row>
                          </Container>
                        ) : (
                          <Container>
                            <Row>
                              <Col lg={4}>
                                <Slide delay="200">
                                  <div className="seo-tag">{seo.tag}</div>
                                </Slide>
                              </Col>
                              <Col lg={8}>
                                <Slide delay="200">
                                  <div className="seo-hero-heading">
                                    <h1> {seo.title} </h1>
                                  </div>
                                </Slide>
                              </Col>
                            </Row>
                          </Container>
                        )}
                      </div>

                      <div className="seo-content">
                        <center>
                          <Container>
                            <InPageDropDown links={seo.linkTitle} />
                            <Row>
                              <Col lg={4}>
                                <SidebarSmall
                                  title="QUICK ACCESS"
                                  items={seo.linkTitle}
                                />
                              </Col>
                              <Col lg={8}>
                                <div
                                  className="blog-single-main-content"
                                  id="seoContent"
                                >
                                  {parse(seo.content.html)}
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </center>
                      </div>
                    </section>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default SeoPost
